<template>
  <div class="col-lg-8 col-md-10">
    <div class="card shadow-lg  border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-5">

        <form ref="form" role="form">
          <div class="row">
            <div class="form-group mb-4 col-md-12">
              <div
                class="input-group input-group-merge input-group-alternative"
              >
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><em class="ni ni-single-02"></em
                  ></span>
                </div>
                <input
                  class="form-control"
                  placeholder="Business name / Trade name / Izina rya business yawe"
                  v-model="post_data.first_name"
                  type="text"
                  required
                />
              </div>
              <div id="error-name" class="text-warning"></div>
            </div>
      
            <div class="col-md-12">
              <phone-input
                :value="phone"
                :errors="errors.phone"
                placeholder="78* *** ***"
                @type="(val) => (this.phone = val)"
              />
            </div>
          
            <div class="form-group col-md-6">
              <div
                class="input-group input-group-merge input-group-alternative"
              >
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><em class="ni ni-lock-circle-open"></em
                  ></span>
                </div>
                <input
                  class="form-control"
                  :placeholder="$t('password')"
                  type="password"
                  v-model="post_data.password1"
                  required
                />
              </div>
            </div>
            <div class="form-group col-md-6">
              <div
                class="input-group input-group-merge input-group-alternative"
              >
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><em class="ni ni-lock-circle-open"></em
                  ></span>
                </div>
                <input
                  class="form-control"
                  :placeholder="$t('confirm-password')"
                  type="password"
                  v-model="post_data.password2"
                  required
                />
              </div>
            </div>
          </div>
          <div>
             <input type="checkbox" value="agreed" v-model="termsAgreed"  />
             <label class="text-muted ml-1">
               I agree to the
             </label><a class="text-primary " style="cursor: pointer;" @click="viewTermsAndConditions"> Terms and Conditions</a>
          </div>
          <div class="text-center">
            <button
              
              type="button"
              @click="register"
              class="btn btn-lg btn-block btn-default my-4"
              :disabled="!termsAgreed"
            >
              {{$t('register')}}          
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <router-link class="text-dark" to="/"
          ><small>{{$t('forgot-password')}}</small></router-link
        >
      </div>
      <div class="col-6 text-right">
        <router-link class="text-dark" to="/login"
          ><small>{{$t('already-have-an-account')}}</small></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import PhoneInput from "../../components/PhoneInput";
export default {
  name: "Register",
  components: {
    "phone-input": PhoneInput,
  },
  data() {
    return {
      post_data: {
        username: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        password: "",
        password1: "",
        password2: "",
      },
      phone: {value: '', full_format: '+250', valid: true},
      errors: {},
      termsAgreed:false
    };
  },
  watch:{},
  methods: {
    register: function () {
      if (!this.phone.valid && this.phone.value.length>0) {
        alert.error("Phone is not valid ")
      }else{
       if(this.validate()){
         var formatted_phone=this.phone.full_format.replaceAll(" ","");
         this.post_data.phone_number =formatted_phone
         this.post_data.password = this.post_data.password1;
         this.post_data.username = formatted_phone
         this.$store.dispatch("register", this.post_data);       }
      }
    },
    viewTermsAndConditions:function(){
        this.$router.push({name:"Terms-and-Conditions"})
    },

    validate:function () {
      let isValid=true;
      if(!this.phone.valid && this.phone.value.length>0){
        isValid=false
      }
      if(this.post_data.first_name.length<3){
        document.getElementById('error-name').innerText="Provide a name";
        isValid=false
      }
      return isValid
    }
  
  },
  created() {
    console.log(this.$refs);
  },
};
</script>

<style>
</style>